@use 'sass:color';
@use 'sass:math';

$form-width: math.div($body-width, 2);
$input-border-rad: $border-rad-small;

$input-class: "input[type="text"], input[type="password"]";

.inline {
    display: inline-flex;
}

.form {
    & #{$input-class} {
        width: 100%;
        height: $button-height;
    }
}

form table {
    &>tr, &>tbody>tr {
        &>td {
            padding: $small-space 0rem;
        }
        &>td:first-child {
            padding-right: $space;
            text-align: right;
            &[colspan="2"] { text-align: left; }
        }
    }
}

#{$input-class}, textarea {
    color: $text;
    background-color: $block-bg;
    border: 1px solid $bg-1;
    border-radius: $input-border-rad;

    transition: background-color $anim-short, border-color $anim-short;

    &:focus {
        border: 1px solid $bg-3;
        background-color: $sub-1;
        outline: none;
    }

    form.form & {
        width: $form-width;
    }
}

textarea {
    height: 8rem;
}

select {
    color: $text;

    border: none;
    background-color: $bg-1;
    border-radius: $btn-border-rad;
    &:hover { background-color: $bg-2; }
    &:active, &.active { background-color: $sub-1; }
    &:disabled { color: $fg-1; &:hover { background-color: $sub-0; } }
}

img.uploaded {
    max-width: $thumb-size * 2;
}
