$container-margin: 2rem;

$min-2col: $container-break;
$min-3col: 1400px;

body.full-container {
  background-color: $sub-1;
  @media (min-width: $min-2col) {
    & .container {
      margin-left: $nav-width + $container-margin;
      margin-right: $container-margin;
      max-width: none;
    }
  }
  @media ((max-width: $small-break) or ((max-width: $container-break) and (min-width: $sidebar-break))) {
    & .container {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.columns {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $min-2col) {
    gap: 1rem;
    &>* {
      width: 100%;
    }
  }
  @media (min-width: $min-2col) {
    & .card {
      margin: 1rem;
    }
  }
  @media (min-width: $min-2col) and (max-width: $min-3col) {
    &>* {
      width: 50%;
    }
  }
  @media (min-width: $min-3col) {
    &>* {
      width: 33.333333%;
    }
  }

  & .card>header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &>h2 {
      @extend .entry-header;
    }
    &>:first-child { flex-grow: 1; }
  }
}
