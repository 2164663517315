@use 'sass:math';

$navbtn-padding: math.div($bar-height, 4);

// Styles to apply when a mobile app replaces the nav bar with native components via strada
[data-bridge-components~="nav"] {
  & nav.main[data-controller~="bridge--nav"] {
    display: none;
  }

  & header.page-head {
    display: none;
  }

  & .container { margin-top: 1rem; }
}


@media (max-width: $sidebar-break) and (min-width: $small-break) {
  nav.main { margin-right: $container-margin; }
  .container { margin-left: $nav-width + $container-margin; }
}

@media (min-width: $small-break) {
  nav.main {
    flex-direction: column;
    top: 0;
    left: 0;
    width: $nav-width;
    height: 100vh;

    &>ul {
      flex-direction: column;
      &>li>ul {
        flex-direction: column;
      }
      & .split>:first-child {
        padding-left: $nav-pad;
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: $small-break) {
  .container { margin-top: $bar-height; }
  nav.main {
    flex-direction: row;
    top: 0;
    left: 0;
    right: 0;
    height: $bar-height;

    &>ul {
      margin: 0;
      flex-direction: row;
      &>li, &>li>.split {
        height: $bar-height;
        & a { line-height: $bar-height; }
      }
      & .split>:last-child {
        display: none;
      }
      &>li>ul { display: none; }
    }
  }
}

nav.main {
  position: fixed;
  padding: 0;
  display: flex;

  box-shadow: 0 0.1rem 0.5rem $shadow;
  background-color: $sub-0;
  z-index: 4;

  & ul {
    padding: 0;
    list-style: none;
    display: flex;

    &>li {
      display: flex;
      flex-direction: column;

      &>ul {
        margin-left: $nav-pad;
        border-left: 2px solid $fg-3;
      }

      &>.split {
        &>a {
          padding: 0 $space;
          & i.icon {
            margin-bottom: -2px;
          }
        }
        display: flex;
        flex-direction: row;
      }
    }

    &.start {
      justify-content: start;
      flex-grow: 1;
    }
    &.end {
      flex-grow: 1;
      justify-content: end;
    }
  }

  & a {
    padding: 0 $nav-pad;
    margin: 0;
    line-height: 2rem;
    color: $text;
    text-decoration: none;
    transition: background-color $anim-short;

    &.logo {
      display: flex;
      align-items: center;
      line-height: $bar-height;
      gap: $space;

      font-size: $font-big;
      font-weight: bold;

      &>img {
        height: $bar-height - $space * 2;
        width: $bar-height - $space * 2;

        border-radius: $border-rad;
      }
    }

    &:hover {
      color: $text;
      background-color: light-dark($primary-hl, $dark-bg-1);
    }
    &:active, &.active {
      color: $text;
      background-color: light-dark($light-bg-0, $dark-sub-1);
    }
  }
}

nav.tabs {
    display: flex;
    flex-direction: row;

    a {
        padding: 0 $nav-pad;
        margin: 0;
        line-height: 2rem;
        color: $text;
        text-decoration: none;
        transition: background-color $anim-short;
        background-color: $sub-0;

        &:hover {
          color: $text;
          background-color: $bg-1;
        }
        &:active, &.active {
          color: $text;
          background-color: $sub-1;
        }

        &:first-child {
            border-top-left-radius: $border-rad;
            border-bottom-left-radius: $border-rad;
        }
        &:last-child {
            border-top-right-radius: $border-rad;
            border-bottom-right-radius: $border-rad;
        }
    }
}
