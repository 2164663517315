$sorter-height: $icon-size * 2;

[data-bridge-components~="toolbar"] {
  & [data-controller~="bridge--toolbar"] {
    display: none;
  }
}

.sorter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    gap: $bigger-space;
    padding: $space 0rem;

    &>span {
        line-height: $sorter-height - 0.1rem;
    }

    &>.right {
        display: flex;
        flex-grow: 1;
        justify-content: end;
        &>* { flex-grow: 0; }
    }

    & .group {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: $space;

        &>label {
            padding: auto $space;
            line-height: $sorter-height;
        }
    }

    &:not(.no-stick) {
        top: -1px;
        & .group, & .buttons {
            margin: -$small-space;
            padding: $small-space;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            background-color: $base;
            border-radius: $border-rad-big;
            transition: box-shadow $anim-short;
        }
        &.stuck {
            & .group, & .buttons {
                box-shadow: 0 0.1rem 0.2rem $shadow;
            }
        }
    }

    @if $sticky-sorter {
        position: sticky;

        @media (min-width: $small-break) {
            top: 0;
        }
        @media (max-width: $small-break) {
            top: $bar-height;
        }

        z-index: 2;

        &.comment-sort {
            // HACK: IDK why.
            top: $bar-height - 0.1rem;
        }
        &.no-stick {
          position: static;
          z-index: 0;
        }
    }
}
