.entry-header {
  &>a {
    color: $fg-0;
    text-decoration: none;
    &:hover {
      color: $fg-3;
      text-decoration: underline;
    }
  }
}

// Heading for post pages or entries in listings
.entry, .content>header {
    padding-top: $big-space;
    &>h1, &>h2, &>h3, &>.meta {
        margin-top: 0;
        margin-bottom: $space;
    }
    &>h1, &>h2, &>h3 {
        @extend .entry-header;
    }
}
.content {
    overflow-wrap: anywhere;
}
.untitled.content {
    &>header { padding-bottom: $space; }
    &>.body { border-top: none; }
}

.entry.read {
    padding: $big-space 0;
    &>h1, &>h2, &>h3, &>.meta {
        &>a {
            color: $fg-3;
        }
    }
}

.avatar {
    width: $avatar-size;
    height: $avatar-size;
    vertical-align: middle;
    border-radius: $border-rad-small;
    object-fit: cover;

    &.big {
        display: block;
        width: $big-avatar-size;
        height: $big-avatar-size;
        margin: $space;
    }
}


#reader>.content, #reader>.separator {
    margin-bottom: 4rem;
}
