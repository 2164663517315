$media-bg: black;

img {
    max-width: 100%;
    max-height: 70vh;
    width: auto;
    height: auto;
}

video {
  width: 100%;
  max-height: 70vh;
  background-color: $media-bg;
}

.media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 70vh;
  overflow: hidden;
  border-radius: $border-rad;
  background-color: $media-bg;

  &.expanded {
    flex-direction: column;
    max-height: none;
    gap: $big-space;

    &>img, &>video {
      width: 100%;
      max-height: none;
      width: auto;
      height: auto;
    }
  }
}
