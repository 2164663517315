@use 'sass:math';

@media (prefers-reduced-motion: no-preference) {
    html.smooth {
        scroll-behavior: smooth;
    }
}

body {
    background-color: $base;
    color: $text;
    font-family: sans-serif;
    max-width: 100vw;
    margin: 0;
}

a {
    color: $primary;
}
a.bare {
    text-decoration: none;
}

blockquote {
    margin-left: $big-space;
    padding-left: $big-space;
    border-left: $line-width solid $bg-3;
}

.turbo-progress-bar {
    background-color: $primary;
}

.hidden {
    display: none !important;
}

.container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $container-break) {
        margin-left: $container-margin;
        margin-right: $container-margin;
    }
}

::-webkit-scrollbar {
    width: $space;
}
::-webkit-scrollbar-thumb {
    border-radius: $border-rad;
    background: $bg-1;
}
::-webkit-scrollbar-track {
    background: $sub-1;
}

.meta {
    font-size: $font-small;
}

.listing {
    display: flex;
    flex-direction: column;

    &>* {
        padding: $space 0rem;
        border-top: 1px $bg-0 solid;
        &:first-child { border-top: none; }
    }
}

.badge {
    margin: $small-space;
    font-size: $font-small;
    color: $fg-0;
    background-color: $bg-2;
    padding: $small-space;
    border-radius: $border-rad;
}

table.table {
    width: 100%;

    &>thead>tr>td {
        padding-bottom: 4px;
        border-bottom: 1px solid $fg-3;
    }

    & td.align-end {
        text-align: end;
    }
}

.end-pad {
    margin-top: 42rem;
}
