@use 'sass:list';

$icon-offset: 4px;
i.icon {
    display: inline-block;
    width: $icon-size + 0.001rem; // Fixes icon edges being cut off
    height: $icon-size + 0.001rem;
    background-color: $text;

    .button>& {
      vertical-align:baseline;
      width: $icon-size;
      height: $icon-size;
      margin-top: $icon-offset;
      margin-bottom: -$icon-offset;
    }
    .dropdown-menu>li>.button>& {
      translate: 0 -0.05rem;
      margin-left: -0.1rem;
    }
    h1>& {
      translate: 0 -0.2rem;
    }

    &.big { transform: scale(1.5); }
    &.bigger { transform: scale(1.8); }
    &.biggest { transform: scale(2.0); }

    &.logo {
        mask: url('data-url:../img/logo.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.refresh {
        mask: url('data-url:../img/refresh.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.plus {
        mask: url('data-url:../img/plus.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.link {
        mask: url('data-url:../img/link.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.net {
        mask: url('data-url:../img/net.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.star {
        mask: url('data-url:../img/star.svg') list.slash(0 0, $icon-size $icon-size);
        .button:active>&, .button.active>& {
          mask: url('data-url:../img/star-solid.svg') list.slash(0 0, $icon-size $icon-size);
          background-color: $yellow;
        }
    }
    &.star-solid {
        mask: url('data-url:../img/star-solid.svg') list.slash(0 0, $icon-size $icon-size);
        .button:active>&, .button.active>& { background-color: $yellow; }
    }
    &.vdots {
        mask: url('data-url:../img/vdots.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.search {
        mask: url('data-url:../img/search.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.info {
        mask: url('data-url:../img/info.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.edit {
        mask: url('data-url:../img/edit.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.check {
        mask: url('data-url:../img/check.svg') list.slash(0 0, $icon-size $icon-size);
        .button:active>&, .button.active>& {
            background-color: $green;
        }
    }
    &.eye {
        mask: url('data-url:../img/eye.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.book {
        mask: url('data-url:../img/book.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.clock {
        mask: url('data-url:../img/clock.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.pin {
        mask: url('data-url:../img/pin.svg') list.slash(0 0, $icon-size $icon-size);
        margin-left: 0.015rem;
        margin-right: -0.015rem;
    }
    &.thread {
        mask: url('data-url:../img/thread.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.tag {
        mask: url('data-url:../img/tag.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.download {
        mask: url('data-url:../img/download.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.comment {
        mask: url('data-url:../img/comment.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.exclaim {
        mask: url('data-url:../img/exclaim.svg') list.slash(0 0, $icon-size $icon-size);
    }
    &.important {
        mask: url('data-url:../img/exclaim.svg') list.slash(0 0, $icon-size $icon-size);
        background-color: $green;
    }
}
