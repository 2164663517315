@use 'sass:math';
@use 'sass:color';
@use 'sass:map';

.comments, .root {
    @media (max-width: $sidebar-break) {
        max-width: 100vw;
        overflow-x: hidden;
    }
    @media (max-width: $container-break) {
        margin-left: -$container-margin * 0.5;
    }
}
@media (max-width: $small-break) {
  .comment.color-0 {
    margin-left: -$expander-width;
  }
}

.comment, .thread {
    // Spacing between separate branches of the comment tree
    display: flex;
    flex-direction: column;

    margin: $comment-tree-spacing 0rem;
    display: grid;
    grid-template-columns: $expander-width 1fr;
    transition: background-color $anim-short;

    &>h1 {
        margin: 0;
    }

    &.highlight {
        background-color: $bg-1;
    }

    // The parent expander (solid line) goes in the top left grid cell
    &>.expander.parent {
        grid-column: 1; grid-row: 1;
        &>div { border-left: $line-width solid; }
    }
    // The comment body goes to the right of it
    &>.content {
        grid-column: 2; grid-row: 1;
        padding-left: $space;
        &>.body {
            & h1 {
                margin-top: $space;
                margin-bottom: $small-space;
                font-size: 22pt;
            }
            &>p {
                margin: $space 0rem;
            }
        }
        &>.buttons {
            margin-top: $space;
        }
    }

    // Child expander (dashed line) in bottom left
    &>.expander.child {
        grid-column: 1; grid-row: 2;
        &>div { border-left: $line-width dashed; }
    }
    // Child comments to the right of it
    &>.comments {
        grid-column: 2;
        grid-row: 2;

        display: flex;
        flex-direction: column;
        align-items: start;
    }

    &>.expander {
        width: $expander-width;
        height: 100%;
        &>div {
            width: $expander-width;
            height: 100%;
            margin-left: math.div($expander-width, 2) - 0.05rem;
        }
    }


    @media (prefers-reduced-motion: no-preference) {
        &.animate {
            transition: max-height 0.2s ease-in-out, background-color $anim-short;
        }
    }
    &.animate {
        overflow: hidden;
    }
}

// Set border colors based on the color number given in the comment's class
$comment-colors: (
  "0": ("light": $light-red, "dark": $dark-red),
  "1": ("light": $light-orange, "dark": $dark-orange),
  "2": ("light": $light-yellow, "dark": $dark-yellow),
  "3": ("light": $light-green, "dark": $dark-green),
  "4": ("light": $light-blue, "dark": $dark-blue),
  "5": ("light": $light-purple, "dark": $dark-purple)
);

@each $num, $color in $comment-colors {
  .comment.color-#{$num}, .thread.color-#{$num} {
    &>.expander.parent {
      &>div { border-left-color: light-dark(map.get($color, "light"), map.get($color, "dark")); }
      &.clickable:hover, &.hover {
        &>div {
          border-left-color: light-dark(
            color.adjust(map.get($color, "light"), $alpha: -0.5),
            color.adjust(map.get($color, "dark"), $lightness: +10%)
          );
        }
      }
    }
    &>.expander.child {
      &>div {
        border-left-color: light-dark(
          color.adjust(map.get($color, "light"), $alpha: -0.5),
          color.adjust(map.get($color, "dark"), $alpha: -0.5)
        );
      }
      &.clickable:hover, &.hover {
        &>div {
          border-left-color: light-dark(
            color.adjust(map.get($color, "light"), $alpha: -0.7, $saturation: -10%),
            color.adjust(map.get($color, "dark"), $alpha: -0.2, $lightness: +10%)
          );
        }
      }
    }
  }
}
