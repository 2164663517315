$btn-padx: $small-space;
$btn-pady: $spacey;

button {
    background-color: none;
    border: none;
    font-size: $font-normal;
}

.button {
    position: relative;
    overflow: hidden;
    font-size: $font-small;
    text-decoration: none;
    border: none;
    border-radius: $btn-border-rad;
    padding: $btn-padx $btn-pady;
    line-height: $button-height;
    display: inline-block;

    transition: background-color $anim-short;

    color: $text;
    background-color: light-dark($light-bg-0, $dark-bg-1);
    &:hover { background-color: light-dark($light-sub-1, $dark-bg-2); }
    &:active, &.active { background-color: light-dark($light-bg-1, $dark-sub-1); }
    &:disabled { color: $fg-1; &:hover { background-color: light-dark($light-sub-0, $dark-sub-0); } }

    &.primary {
        color: $base;
        background-color: $primary;
        &:hover { background-color: $primary-hl; }
        &:active, &.active { background-color: $primary-active; }
        &:disabled {
            color: $bg-1;
            background-color: $primary-disabled;
            &:hover { background-color: $primary-disabled; }
        }
    }

    &.negative {
        color: $base;
        background-color: $red;
        &:hover { background-color: $red-hl; }
        &:active, &.active { background-color: $red-active; }
        &:disabled {
            color: $bg-1;
            background-color: $red-disabled;
            &:hover { background-color: $red-disabled; }
        }
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .button {
        border-radius: 0rem;
        &:first-child {
            border-top-left-radius: $btn-border-rad;
            border-bottom-left-radius: $btn-border-rad;
        }
        &:last-child {
            border-top-right-radius: $btn-border-rad;
            border-bottom-right-radius: $btn-border-rad;
        }
    }

    &.col {
        flex-direction: column;
        align-items: stretch;

        & .button {
            text-align: center;

            border-radius: 0rem;
            border-top: 1px $bg-2 solid;
            &:first-child {
                border-top: none;
                border-top-left-radius: $btn-border-rad;
                border-top-right-radius: $btn-border-rad;
            }
            &:last-child {
                border-bottom-left-radius: $btn-border-rad;
                border-bottom-right-radius: $btn-border-rad;
            }
        }
    }
}

.button>.count {
    margin-left: $small-space;
}

%blended, .button.blended {
    border-radius: $border-rad !important;
    background-color: rgba(0, 0, 0, 0);
    &:hover { background-color: light-dark($light-sub-1, $dark-bg-2); }
    &:active, &.active { background-color: light-dark($light-bg-1, $dark-sub-1); }
    &:disabled { color: $fg-1; &:hover { background-color: rgba(0, 0, 0, 0); } }
}

.sorter {
  gap: $small-space;
  & .button {
      @extend %blended;
  }
}

@property --a {
  syntax: '<number>';
  initial-value: 0;
  inherits: false
}

@property --r {
  syntax: '<length-percentage>';
  initial-value: 0%;
  inherits: false
}

$ripple-color: light-dark(
  rgba(100, 100, 100, var(--a)),
  rgba(255, 255, 255, var(--a)),
);

// Ripple animations
// Adapted from https://codepen.io/thebabydino/pen/LYZpEVy
.button {
  position: relative;
  overflow: hidden;
  background: 
    radial-gradient(
      circle at var(--x, 50%) var(--y, 50%),
      $ripple-color calc(var(--r) - 1px),
      transparent var(--r)
    )
    light-dark($light-bg-0, $dark-bg-1);
  animation: a .6s linear;
}

.button.ani { animation-name: fade, grow }

@keyframes fade { 0% { --a: .7 } }
@keyframes grow { to { --r: 200% } }


/*
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
*/
