#alerts {
    position: fixed;
    top: $bar-height + $space;
    left: 0;
    right: 0;
    z-index: 5;
}

#flashes {
    margin-top: 1rem;
}

#alerts, #flashes {
    width: $body-width;
    margin: 0rem auto;
    display: flex;
    flex-direction: column;
    gap: $space;
}
.alert {
    padding: 0rem $big-space;
    border-radius: $border-rad;
    background-color: $sub-1;

    &.good {
        background-color: $green;
        color: $base;
    }
    &.bad {
        background-color: $red;
        color: $base;
    }

    &>h1 { font-size: $font-big; }
}
