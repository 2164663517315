@use 'sass:color';
@use 'sass:math';

// Catppuccin Latte
$light-red: #d20f39;
$light-orange: #fe640b;
$light-yellow: #df8e1d;
$light-green: #40a02b;
$light-blue: #1e66f5;
$light-purple: #8839ef;
$light-lavender: #7287fd;

$light-text: #4c4f69;
$light-fg-0: #5c5f77;
$light-fg-1: #6c6f85;
$light-fg-2: #7c7f93;
$light-fg-3: #8c8fa1;
$light-bg-3: #9ca0b0;
$light-bg-2: #acb0be;
$light-bg-1: #bcc0cc;
$light-bg-0: #ccd0da;
$light-base: #eff1f5;
$light-sub-0: #e6e9ef;
$light-sub-1: #dce0e8;

$light-primary: $light-lavender;
$light-secondary: $light-blue;

// Catppuccin Macchiato
$dark-red: #ed8796;
$dark-orange: #f5a97f;
$dark-yellow: #eed49f;
$dark-green: #a6da95;
$dark-blue: #8aadf4;
$dark-purple: #c6a0f6;
$dark-lavender: #b7bdf8;

$dark-text: #cad3f5;
$dark-fg-0: #b8c0e0;
$dark-fg-1: #a5adcb;
$dark-fg-2: #939ab7;
$dark-fg-3: #8087a2;
$dark-bg-3: #6e738d;
$dark-bg-2: #5b6078;
$dark-bg-1: #494d64;
$dark-bg-0: #363a4f;
$dark-base: #24273a;
$dark-sub-0: #1e2030;
$dark-sub-1: #181926;

$dark-primary: $dark-lavender;
$dark-secondary: $dark-blue;

$shadow: rgba(0, 0, 0, 0.27);

// Auto
$red: light-dark($light-red, $dark-red);
$orange: light-dark($light-orange, $dark-orange);
$yellow: light-dark($light-yellow, $dark-yellow);
$green: light-dark($light-green, $dark-green);
$blue: light-dark($light-blue, $dark-blue);
$purple: light-dark($light-purple, $dark-purple);
$lavender: light-dark($light-lavender, $dark-lavender);

$text: light-dark($light-text, $dark-text);
$fg-0: light-dark($light-fg-0, $dark-fg-0);
$fg-1: light-dark($light-fg-1, $dark-fg-1);
$fg-2: light-dark($light-fg-2, $dark-fg-2);
$fg-3: light-dark($light-fg-3, $dark-fg-3);
$bg-3: light-dark($light-bg-3, $dark-bg-3);
$bg-2: light-dark($light-bg-2, $dark-bg-2);
$bg-1: light-dark($light-bg-1, $dark-bg-1);
$bg-0: light-dark($light-bg-0, $dark-bg-0);
$base: light-dark($light-base, $dark-base);
$sub-0: light-dark($light-sub-0, $dark-sub-0);
$sub-1: light-dark($light-sub-1, $dark-sub-1);

$primary: $lavender;
$primary-hl: light-dark(
  color.adjust($light-primary, $lightness: +20%),
  color.adjust($dark-primary, $lightness: +15%)
);
$primary-active: light-dark(
  color.adjust($light-primary, $saturation: -20%, $lightness: -20%),
  color.adjust($dark-primary, $saturation: -20%, $lightness: -20%)
);
$primary-disabled: light-dark(
  color.adjust($light-primary, $saturation: -40%),
  color.adjust($dark-primary, $saturation: -40%)
);

$secondary: $blue;

$red-hl: light-dark(
  color.adjust($light-red, $lightness: -15%),
  color.adjust($dark-red, $lightness: +15%)
);
$red-active: light-dark(
  color.adjust($light-red, $saturation: -20%, $lightness: -20%),
  color.adjust($dark-red, $saturation: -20%, $lightness: -20%)
);
$red-disabled: light-dark(
  color.adjust($light-red, $saturation: -40%),
  color.adjust($dark-red, $saturation: -40%)
);


$body-bg: $base;
$block-bg: $sub-0;

$anim-short: 0.1s;

$font-biggest: 18pt;
$font-bigger: 16pt;
$font-big: 14pt;
$font-normal: 12pt;
$font-small: 10pt;

$icon-size: 1rem;
$avatar-size: 1.5rem;
$big-avatar-size: 4rem;
$max-banner-height: 25rem;
$logo-size: 2rem;
$button-height: $icon-size * 1.5;

$thumb-size: 6rem;


// Spacing
$small-space: 0.2rem;
$space: 0.5rem;
$spacey: 0.7rem;
$big-space: 1rem;
$bigger-space: 1.5rem;

$nav-pad: $big-space;
$thumb-padding: $small-space;
$thumb-banner-height: 1.2rem;


// Max width of long-form text content like post and comment bodies. Keeping
// this relatively low is important for readability, but annoying people will
// complain if they think it's set too low.
$body-width: 50rem;
// Maximum width of the full page content
$content-width: 50rem;
// Width of nav sidebar
$nav-width: 13rem;

// Margins around the content
$container-margin: $big-space;

// Viewport width at which the content of the page no longer has its width
// constrained by `$content-width`, accounting for margins.
$container-break: $nav-width + $content-width + $container-margin * 2;
// Viewport width at which the content of the page no longer has its width
// constrained by `$content-width`, accounting for margins and the sidebar
$sidebar-break: $content-width + $container-margin * 2 + $nav-width * 2;
// Switch to more horizontally compact layout at this point
$small-break: $content-width * 0.8;

$bar-height: 2.5rem;

$comment-header-height: $bigger-space;
$comment-tree-spacing: $space;
$expander-width: $big-space;

$border-rad-big: 0.6rem;
$border-rad: 0.4rem;
$border-rad-small: 0.2rem;
$btn-border-rad: $border-rad-small;


// Whether the sidebar sticks to the top of the screen when scrolling.
$sticky-sidebar: true;
// Whether the sort form sticks to the top of the screen when scrolling so it remains visible.
$sticky-sorter: true;
// Whether the main nav bar sticks to the top of the screen when scrolling.
// This must be true if the above is true, otherwise weird stuff will happen.
$sticky-nav: true; // TODO: Implement disabling this


$comment-header-height: $bigger-space;
$comment-tree-spacing: $space;
$expander-width: $big-space;
$line-width: 0.2rem;


:root {
  color-scheme: light dark;
}


@import "components.scss";
@import "buttons.scss";
@import "form.scss";
@import "dropdown.scss";
@import "icon.scss";
@import "nav.scss";
@import "sort.scss";
@import "alert.scss";
@import "loadani.scss";
@import "entry.scss";
@import "media.scss";
@import "comments.scss";
@import "cards.scss";
@import "columns.scss";
