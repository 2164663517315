$card-padding: $bigger-space;

.card {
    box-shadow: 0 0.1rem 0.2rem $shadow;
    background-color: $body-bg;
    padding: $card-padding;
    @media (min-width: $container-break) {
      border-radius: $border-rad-big;
    }
}

body.card-view {
  background-color: $sub-1;

  @media (min-width: $container-break) {
    margin-top: $bigger-space;
  }
  @media (max-width: $container-break) and (min-width: $small-break) {
    & .container {
      margin-left: $nav-width;
      margin-right: 0;
    }
  }
  @media ((max-width: $small-break) or ((max-width: $container-break) and (min-width: $sidebar-break))) {
    & .container {
      margin-left: 0;
      margin-right: 0;
    }
  }
  & main>.content {
    @extend .card;
  }
}
