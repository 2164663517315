.dropdown {
  display: flex;
  flex-direction: column;
  &>input { display: none; }
  &>.dropdown-menu {
    z-index: 1;
    transform: translateY($big-space);
    display: none;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    list-style-type: none;
    border-radius: $border-rad;
    background-color: $sub-0;
    box-shadow: 0 0.1rem 0.2rem $shadow;

    padding-inline-start: 0rem;
    overflow: hidden;

    &>li {
      display: flex;
    }
    &>li>* {
      width: 100%;
      padding: $space $big-space;
      color: $text;
      background-color: $sub-0;
      text-decoration: none;

      transition: background-color $anim-short;

      &:hover { background-color: $bg-1; }
      &:active, &.active { background-color: $sub-1; }

      &:first-child {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:last-child {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
    &>li>.button {
      line-height: $icon-size;
      text-align: left;
    }
  }
  &>input:checked ~ label {
    background-color: $sub-1 !important;
  }
  &>input:checked ~ .dropdown-menu {
    display: flex;
  }
}
